.btn-oa {
  background-color: silver !important;
  border-color: silver !important;
}
.btn-ot {
  background-color: gold !important;
  border-color: gold !important;
  color: black;
}

.text-gold {
  color: gold !important;
}
.text-silver {
  color: silver !important;
}
.text-bronze {
  color: #cd7f32 !important;
}

.border-gold {
  border-color: gold !important;
}
.border-silver {
  border-color: silver !important;
}
.border-bronze {
  border-color: #cd7f32 !important;
}
